import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./NotFound";
import Home from "./Components/Home";
import CompleteRegistration from "./Components/CompleteRegistration";
import Success from "./Components/Success";
import Finish from "./Components/Finish";
const root = ReactDOM.createRoot(document.getElementById("root"));

export default function App() {
  return (
    <div className="transition duration-500 bg-bodybg dark:bg-bodybgdark">
      <Router>
        <Routes>
          <Route exact path="/register" element={<CompleteRegistration />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/finish" element={<Finish />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
