import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className=" min-h-screen">
      <div className="h-20"></div>
      <div className="h-[70vh] w-full flex flex-col justify-center items-center">
        <span className="font-mont text-3xl text-dark_text dark:text-light_text ">
          This page is not available
        </span>
        <Link
          className="px-[63px] mt-[40px] py-[21px] bg-[#3DA84A] text-white rounded-[5px] flex items-center gap-2"
          to="/"
          style={{ textDecoration: "none" }}
        >
          Go Back Home
        </Link>
      </div>
      <div className="h-20"></div>
    </div>
  );
}

export default NotFound;
