import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalComp({ show = true, handleClose = {} }) {
  const redirectToWhatsApp = () => {
    // Replace the phone number and message with your desired values
    const phoneNumber = "+23409160717495";
    const message = "Hello, how can I help you?";

    // Generate the WhatsApp URL with the phone number and message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message,
    )}`;

    // Redirect the user to the WhatsApp URL
    window.location.href = url;
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="p-0 m-0  d-lg-block">
        <div></div>
        <div className="flex justify-center pt-[100px]">
          <img src="/icon/logo.jpg" className="w-[40px]" />
        </div>
        <div className=" pt-[20px] flex flex-col justify-between">
          <div>
            <h4 className="text-center fw-bold mb-5 flex gap-2 items-center justify-center">
              Welcome to PharmaServ <img src="/icon/hand.svg" alt="pharam1" />
            </h4>
            <p className="text-center text-black text-[20px] mb-24">
              Thank you for signing up!
              <br />
              Your account has been created succesfully. <br />
              In a few minutes, you'll receive a password to access your account
              in your email.
            </p>
            <div className="flex justify-center mb-2">
              <button
                className="bg-[#3DA84A] text-white h-[54px] text-center w-[60%] rounded-sm"
                onClick={() =>
                  window.open("https://demo.pharmaserv.ng/", "_blank")
                }
              >
                Proceed to Login
              </button>
            </div>
            {/* <div className="text-center text-[#282728] text-[18px]">
              Didn’t get the password?{" "}
              <span className="text-[#4F008A]">Resend</span>
            </div> */}
          </div>
          <div
            onClick={() => redirectToWhatsApp()}
            className="text-end mb-3 mr-10 flex justify-end items-center gap-1 cursor-pointer"
          >
            Need Help? <img alt="whatsapp" src="/icon/whatsapp.svg" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
