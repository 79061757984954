import React from "react";

export const TextInputComp = ({
  label,
  placeholder,
  type,
  disabled = false,
  suffixIcon,
  mb = "24px",
  error,
  ...props
}) => {
  return (
    <div className={`mb-[${mb}]`}>
      <div className="mb-[10px] text-[18px] font-medium">{label}</div>
      <div className="w-full border-rgba(79, 0, 138, 0.1) hover:border-rgba(79, 0, 138, 0.1) focus:border-rgba(79, 0, 138, 0.1) border-[1px] py-[13px] px-[10px] lg:px-[26px] placeholder:text-[#666666] flex items-center">
        {suffixIcon && <div>{suffixIcon}</div>}
        <input
          disabled={disabled}
          type={type || "text"}
          className="w-full outline-none border-0"
          placeholder={placeholder}
          {...props}
        />
      </div>
      {error && <span className="text-danger">{error}</span>}
    </div>
  );
};
