import React from "react";
import { Link } from "react-router-dom";

function Success() {
  const email = new URLSearchParams(window.location.search).get("email");
  return (
    <div className=" min-h-screen">
      <div className="h-[100vh] w-full flex flex-col justify-center items-center">
        <img src="/icon/mail.svg" alt="mail" />
        <p className="font-Satoshi  md:text-[36px] text-[16px] text-[#050213] font-[700] mt-[50px]">
          Thank you for signing up!
        </p>
        <div className="flex justify-center items-center text-center md:w-[600px] w-[300px]">
          <p className="font-Satoshi md:text-[20px] text-[14px] font-[400] text-[#555555]">
            Almost there! We’ve sent an email to {email}. Follow the prompt to
            complete your registration and activate your account.
          </p>
        </div>
        <Link
          className="px-[63px] mt-[40px] py-[15px] bg-[#8652CE] text-white rounded-[12px] flex items-center gap-2"
          to="/"
          style={{ textDecoration: "none" }}
        >
          Return to previous page
        </Link>
      </div>
    </div>
  );
}

export default Success;
